import 'swiper/swiper-bundle.min.css';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import '../css/load.scss';

import './jquery-ui.min.js';
import Swiper from 'swiper/swiper-bundle.min.mjs';
import { Fancybox } from '@fancyapps/ui';
import List from 'list.js';

(function ($) {
	const $win = $(window);
	const $doc = $(document);

	padKartSection();
	padPilotSection();
	initHeaderMenuToggler();
	initSlider('.slider-karts');
	initFancyBoxAlbum('album');

	initStoreHeaderMenuToggler();
	initLanguageSwitcher();

	handleMainMenuFilters();
	handleOverlayApplyButton();
	handleOverlayResetButton();
	handleOverlayCloseButton();
	displayOverlayAllFilters();
	initOverlayCategoryVisibility();

	initSubcategoryToggles();

	initDynamicProductSearch();
	displayOverlaySearch()
	initOverlaySearch();


	function addOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').addClass('no-scroll');
		$overlay.addClass('active');
	}

	function removeOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').removeClass('no-scroll');
		$overlay.removeClass('active');
	}

	function scrollToSection() {
		$('a.btn-scroller').on('click', function (e) {
			let sectionId = $(this).attr('href');
			if (!sectionId.startsWith('#')) {
				return;
			}

			e.preventDefault();

			$('html, body').animate(
				{
					scrollTop: $(sectionId).offset().top - 40
				},
				1000
			);
		});
	}

	function initHeaderMenuToggler() {
		if (!$('.header__menu-toggle').length) {
			return;
		}

		const $header = $('.header__container');

		$('.header .header__menu-toggle').on('click', function (e) {
			$(this).closest('.header__container').toggleClass('menu-open');
			addOverlay('.app-loader-overlay');
			e.preventDefault();
		});

		$('.header__menu-close svg').on('click', function (e) {
			$('.header__container').toggleClass('menu-open');
			e.preventDefault();
			removeOverlay('.app-loader-overlay');
		});

		$doc.mouseup(function (e) {
			if ($header.hasClass('menu-open')) {
				if (!$header.is(e.target) && $header.has(e.target).length === 0) {
					$header.removeClass('menu-open');
					removeOverlay('.app-loader-overlay');
				}
			}
		});
	}

	function padKartSection() {
		if (!$('.section-wide .section__inner').length) {
			return;
		}

		let tempBodyWidth = $win.width();
		if (tempBodyWidth <= 1025) {
			return;
		}

		$win.on('load resize', function () {
			let bodyWidth = $win.width();
			let $parent = $('.section-wide');

			if (bodyWidth <= 1025) {
				$parent.find('.section__inner').css('paddingRight', 0);
				return;
			}

			let bigShellWidth = $parent.find('.shell.shell--alt').width();
			let smallShellWidth = $('.shell:not(.shell--alt,.shell--wild)').width();
			let paddingInt = Math.floor(bigShellWidth - smallShellWidth) / 2;

			$parent.find('.section__inner').css('paddingRight', paddingInt);
		});
	}

	function padPilotSection() {
		if (!$('.shell--wild').length) {
			return;
		}

		let tempBodyWidth = $win.width();
		if (tempBodyWidth > 1400 || tempBodyWidth <= 1025) {
			return;
		}

		$win.on('load resize', function () {
			let bodyWidth = $win.width();
			if (bodyWidth > 1400 || bodyWidth <= 1025) {
				$('.shell--wild .section__body').css('paddingLeft', 0);
				return;
			}

			let smallShellWidth = $('.shell:not(.shell--alt, .shell--wild)').width();
			let paddingInt = Math.floor(bodyWidth - smallShellWidth) / 2;

			$('.shell--wild .section__body').css('paddingLeft', paddingInt);
		});
	}

	function initSlider(elementSelector) {
		const swiper = new Swiper('.swiper' + elementSelector, {
			loop: true,
			slidesPerView: 1,
			spaceBetween: 16,

			pagination: {
				el: '.swiper-pagination'
			},
			navigation: {
				nextEl: `${elementSelector} + .swiper-buttons .swiper-button-next`,
				prevEl: `${elementSelector} + .swiper-buttons .swiper-button-prev`
			}
		});
	}

	function initFancyBoxAlbum(dataSelector) {
		const options = {
			Thumbs: {
				type: 'classic'
			}
		};

		Fancybox.bind(`[data-fancybox="${dataSelector}"]`, options);
	}

	function initStoreHeaderMenuToggler() {
		if (!$('.header__menu-toggle').length) {
			return;
		}

		const $header = $('.header__container');

		$('.header-store .header__menu-toggle').on('click', function (e) {
			$(this).closest('.header__container').toggleClass('menu-open');
			$('.app-loader-overlay').css('z-index', '2');
			addOverlay('.app-loader-overlay');
			e.preventDefault();
		});

		$('.header__menu-close svg').on('click', function (e) {
			$('.header__container').removeClass('menu-open');
			e.preventDefault();
			removeOverlay('.app-loader-overlay');
		});

		$doc.mouseup(function (e) {
			if ($header.hasClass('menu-open')) {
				if (!$header.is(e.target) && $header.has(e.target).length === 0) {
					$header.removeClass('menu-open');
					removeOverlay('.app-loader-overlay');
				}
			}
		});
	}

	function initLanguageSwitcher() {
		if (!$('#lang-switcher-btn').length) {
			return;
		}

		const $langSwitcher = $('#lang-switcher');

		$('#lang-switcher-btn').on('click', function (e) {
			e.preventDefault();

			$langSwitcher.toggleClass('active');
			$('.app-loader-overlay').css('z-index', '999997');
			addOverlay('.app-loader-overlay');
		});

		$doc.mouseup(function (e) {
			if ($langSwitcher.hasClass('active')) {
				if (!$langSwitcher.is(e.target) && $langSwitcher.has(e.target).length === 0) {
					$langSwitcher.removeClass('active');
					$('.app-loader-overlay').css('z-index', '101');
					removeOverlay('.app-loader-overlay');
				}
			}
		});
	}

	// Global variable to store selected subcategories
	var selectedSubcategories = [];
	var isAvailable = false;

	// Function to handle the changes in the main menu filter
	function handleMainMenuFilters() {
	    $(document).on('change', 'input[name="subcategory[]"], input[name="availability"]', function() {
	        selectedSubcategories = [];
	        
	        // Collect selected subcategories from the main menu
	        $('input[name="subcategory[]"]:checked').each(function() {
	            selectedSubcategories.push($(this).val());
	        });

	        isAvailable = $('input[name="availability"]').is(':checked');

	        // Sync the selected subcategories with the overlay
	        updateOverlayCheckboxes(selectedSubcategories);

	        // Perform AJAX request for filtering
	        applyProductFilter();
	    });
	}

	// Function to update the overlay checkboxes based on selected subcategories
	function updateOverlayCheckboxes(selectedSubcategories) {
	    $('input[name="overlay_subcategory[]"]').each(function() {
	        // Check the overlay checkboxes that match the selected subcategories
	        if (selectedSubcategories.includes($(this).val())) {
	            $(this).prop('checked', true);
	        } else {
	            $(this).prop('checked', false);
	        }
	    });
	}

	// Apply AJAX filter when selections change
	function applyProductFilter() {
	    $.ajax({
	        url: appData.ajaxUrl + '?action=saeba_filter_products', // Change to your AJAX endpoint
	        method: 'GET',
	        data: {
	            subcategory: selectedSubcategories,
	            is_available: isAvailable
	        },
	        success: function(response) {
	            $('.products').html(response); // Update the products
	        },
	        error: function() {
	            $('.products').html('<p>An error occurred. Please try again.</p>'); // Handle errors
	        }
	    });
	}

	// Function to handle the "Apply" button in the overlay
	function handleOverlayApplyButton() {

	    $('#apply-filters-btn').on('click', function(e) {
	        e.preventDefault();

	        // console.log("Apply button clicked");

	        var selectedSubcategoriesOverlay = [];

	        $('input[name="overlay_subcategory[]"]:checked').each(function() {
	            selectedSubcategoriesOverlay.push($(this).val());
	        });


	        if (selectedSubcategoriesOverlay.length > 0) {
	            // Update the global selected subcategories array
	            selectedSubcategories = selectedSubcategoriesOverlay;

	            // Update the main menu checkboxes to reflect the overlay selections
	            updateMainMenuCheckboxes(selectedSubcategories);

	            // Perform AJAX request to filter products
	            applyProductFilter();

	            // Close the overlay after applying the filter
	            $('#overlay-all-filters').removeClass('active');
	           	$('.app-loader-overlay').css('z-index', '101');
				removeOverlay('.app-loader-overlay');

	        } else {

	            alert('Please select at least one subcategory to apply the filter.');
	        }
	    });
	}

	// Function to update the main menu checkboxes when the overlay filter is applied
	function updateMainMenuCheckboxes(selectedSubcategories) {
	    $('input[name="subcategory[]"]').each(function() {
	        if (selectedSubcategories.includes($(this).val())) {
	            $(this).prop('checked', true);
	        } else {
	            $(this).prop('checked', false);
	        }
	    });
	}

	// Function to handle the "Reset" button in the overlay
	function handleOverlayResetButton() {
	    $('#reset-filters-btn').on('click', function(e) {
	        e.preventDefault();

	        // Clear the overlay checkboxes
	        $('input[name="overlay_subcategory[]"]').prop('checked', false);

	        // Clear the main menu checkboxes
	        $('input[name="subcategory[]"]').prop('checked', false);
	        // $('input[name="availability"]').prop('checked', false);

	        // Reset the global selected subcategories array
	        selectedSubcategories = [];
	        // isAvailable = false;

	        // Perform AJAX request to reset the filter (no filters selected)
	        applyProductFilter();

	        // Optionally, close the overlay after resetting
	        $('#overlay-all-filters').removeClass('active');
    		$('.app-loader-overlay').css('z-index', '101');
			removeOverlay('.app-loader-overlay');
	    });
	}

	// Function to handle the "Close" button in the overlay
	function handleOverlayCloseButton() {
		const $overlayAllFilters = $('#overlay-all-filters');

		// Close the overlay when clicking the close button (SVG)
		$('.overlay-header .header-close').on('click', function () {
		    $overlayAllFilters.removeClass('active');
		    $('.app-loader-overlay').css('z-index', '101');
			removeOverlay('.app-loader-overlay');
		});

		// Close the overlay when clicking outside of it
		$doc.mouseup(function (e) {
			if ($overlayAllFilters.hasClass('active')) {
				if (!$overlayAllFilters.is(e.target) && $overlayAllFilters.has(e.target).length === 0) {
					$overlayAllFilters.removeClass('active');
					$('.app-loader-overlay').css('z-index', '101');
					removeOverlay('.app-loader-overlay');
				}
			}
		});	
	}

	// When the overlay opens, check the corresponding checkboxes
	$('#overlay-all-filters').on('show', function() {
	    updateOverlayCheckboxes(selectedSubcategories);
	});

	// Function to display overlay filter menu
	function displayOverlayAllFilters() {
	    const $overlayAllFilters = $('#overlay-all-filters');
	    
	    if (!$('.btn-show-more').length && !$('.btn--filter').length) {
	        return;
	    }

	    $('.btn-show-more').on('click', function (e) {
	        e.preventDefault();

	        const categoryId = $(this).data('category-id');

	        $overlayAllFilters.addClass('active');
	        $('.app-loader-overlay').css('z-index', '999997');
	        addOverlay('.app-loader-overlay');

	        if (categoryId) {
	            $('.overlay-subcategory-list').hide();

	            const $subcategoryList = $(`#overlaySubcategoryList-${categoryId}`);
	            if ($subcategoryList.length) {
	                $subcategoryList.show();
	            }
	        }
	    });

	    $('.btn--filter').on('click', function (e) {
	        e.preventDefault();

	        if ($(window).width() > 640) {
	            const firstCategoryId = $('.btn--overlay-category').data('category-id');

	            $overlayAllFilters.addClass('active');
	            $('.app-loader-overlay').css('z-index', '999997');
	            addOverlay('.app-loader-overlay');

	            $('.overlay-subcategory-list').hide();

	            const $subcategoryList = $(`#overlaySubcategoryList-${firstCategoryId}`);
	            if ($subcategoryList.length) {
	                $subcategoryList.show();
	            }
	        } else {

		        $overlayAllFilters.addClass('active');
		        $('.app-loader-overlay').css('z-index', '999997');
		        addOverlay('.app-loader-overlay');

	            $('.categories-column').show();
	            $('.overlay-subcategory-list').hide();
	        }
	    });
	}

	// Function to display overlay filter menu
	function initOverlayCategoryVisibility() {

		function resetCategories() {
	        if ($(window).width() > 640) {
	            $('.categories-column').show();
	        } else {
	            $('.categories-column').hide();
	        }
	    }

	    resetCategories();

		$(window).on('resize', function() {
	        resetCategories();
	    });

	    $('.btn--overlay-category').on('click', function (e) {
	        e.preventDefault();

	        const categoryId = $(this).data('category-id');
	        const $subcategoryList = $(`#overlaySubcategoryList-${categoryId}`);

	        if ($(window).width() <= 640) {
	            $('.categories-column').hide();
	            $('.overlay-subcategory-list').hide();

	            if ($subcategoryList.length) {
	                $subcategoryList.show();
	            }
	        } else {

	            $('.categories-column').show();
	            $('.overlay-subcategory-list').hide();

	            if ($subcategoryList.length) {
	                $subcategoryList.toggle();
	            }
	        }
	    });
	}

	// Function to toggle subcategories
	function initSubcategoryToggles() {
	    // Select all buttons that toggle subcategories
	    const toggleButtons = document.querySelectorAll('.btn--category');

	    toggleButtons.forEach(button => {
	        button.addEventListener('click', () => {

	            // Get the ID of the subcategory container from the aria-controls attribute
	            const targetId = button.getAttribute('aria-controls');
	            const targetElement = document.getElementById(targetId);

	            if (targetElement) {
	                // Check if the subcategory container is currently visible or hidden
	                const isExpanded = button.getAttribute('aria-expanded') === 'true';

	                // Toggle the aria-expanded attribute and the visibility of the target element
	                button.setAttribute('aria-expanded', !isExpanded);
	                targetElement.style.display = isExpanded ? 'none' : 'flex';
	            }
	        });
	    });
	}




	function initDynamicProductSearch() {
	    $('#search_term').on('input', function() {
	        var search_term = $(this).val(); // Get the search query

	        // If the query is not empty, perform an AJAX request
	        if (search_term.length >= 2) { // Only start showing results after 2 characters
	            $.ajax({
	                url: appData.ajaxUrl, // WordPress AJAX URL
	                type: 'GET',
	                data: {
	                    action: 'dynamic_product_search', // The action to be handled by PHP
	                    search_term: search_term // The search query
	                },
	                success: function(response) {
	                    // Empty the previous results
	                    $('#searchDropdown').empty();

	                    if (response.success) {
	                        // If products are found, loop through and display them
	                        if (response.data.length > 0) {
	                            $.each(response.data, function(index, product) {
	                                var li = '<li>';
	                                li += '<input type="checkbox" id="product-' + product.id + '" name="search_product[]" value="' + product.id + '" data-permalink="' + product.permalink + '">';
	                                li += '<label for="product-' + product.id + '">';
	                                li += '<span class="product-name">' + product.name + '</span>';
	                                li += '<span class="category-name">' + product.category + '</span>';
	                                li += '</label>';
	                                li += '</li>';

	                                $('#searchDropdown').append(li);
	                            });
	                        } else {
	                            var li = '<li>';
	                            li += '<span class="no-results">No results found</span>';
	                            li += '</li>';

	                            $('#searchDropdown').append(li);
	                        }
	                    }

	                    // Show the dropdown
	                    $('#searchDropdown').show();
	                },
	                error: function() {
	                    console.log('Error fetching products');
	                }
	            });
	        } else {
	            // Hide and clear the dropdown
	            $('#searchDropdown').hide().empty();
	        }
	    });

	    // Handle checkbox click to redirect to the product page
	    $(document).on('change', 'input[type="checkbox"]', function() {
	        if ($(this).is(':checked')) {
	            var permalink = $(this).data('permalink'); // Get permalink from data attribute
	            if (permalink) {
	                window.location.href = permalink; // Redirect to the product page
	            }
	        }
	    });

	    // Close dropdown if clicked outside of it
	    $(document).on('click', function(e) {
	        // If the clicked element is outside the input or dropdown, hide the dropdown
	        if (!$(e.target).closest('#search_term').length && !$(e.target).closest('#searchDropdown').length) {
	            $('#searchDropdown').hide(); // Close the dropdown
	        }
	    });
	}




	function displayOverlaySearch() {
	    
	    if (!$('.btn--search').length) {
	        return;
	    }

	    const $overlaySearchContainer = $('#overlaySearchContainer'); // Overlay container

	    $('.btn--search').on('click', function (e) {
	        e.preventDefault();

	        $overlaySearchContainer.toggleClass('active');
	    });

		$doc.mouseup(function (e) {
			if ($overlaySearchContainer.hasClass('active')) {
				if (!$overlaySearchContainer.is(e.target) && $overlaySearchContainer.has(e.target).length === 0) {
					$overlaySearchContainer.removeClass('active');
				}
			}
		});	
	}


	function initOverlaySearch() {
	    $('#overlaySearchInput').on('input', function () {
	        var overlay_search_input = $(this).val();

	        // Clear previous results before making a new AJAX call
	        $('#overlaySearchResults').empty();

	        // Only make an AJAX request if the input has 2 or more characters
	        if (overlay_search_input.length >= 2) {
	            $.ajax({
	                url: appData.ajaxUrl, // Make sure this is properly set in your WordPress setup
	                type: 'GET',
	                data: {
	                    action: 'saeba_overlay_search', // The AJAX action name
	                    overlaySearchInput: overlay_search_input // Pass the input value
	                },
	                success: function (response) {
	                	$('#overlaySearchResults').empty();

	                    if (response.success) {

	                        if (response.data.length > 0) {
	                            $.each(response.data, function (index, product) {
	                                var li = `
	                                    <li>
	                                        <input type="checkbox" id="${product.name}"
	                                				class="input-checkbox"
	                                            	name="search_product[]"
	                                            	value="${product.name}" 
	                                            	data-permalink="${product.permalink}">
	                                        <label for="${product.name}">
	                                            <span>
		                                            <span>${product.name}</span>
		                               				<span>${product.category}</span>
	                                			</span>
	                                        </label>
	                                    </li>
	                                `;
	                                $('#overlaySearchResults').append(li);
	                            });
	                        } else {
	                            // Show "No results found" if no products are returned
	                            $('#overlaySearchResults').append(`
	                                <li class="no-results">
	                                    <span>No results found</span>
	                                </li>
	                            `);
	                        }
	                    }
	                },

	                error: function () {
	                    console.error('Error fetching products');
	                }
	            });
	        }

	    });

	   	// Handle checkbox click to redirect to the product page
	    $(document).on('change', 'input[type="checkbox"]', function() {
	        if ($(this).is(':checked')) {
	            var permalink = $(this).data('permalink'); // Get permalink from data attribute
	            if (permalink) {
	                window.location.href = permalink; // Redirect to the product page
	            }
	        }
	    });

	}




})(jQuery);
